import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Subject } from 'rxjs';

import { LocalStorageService } from '../common/localstorage.service';
import { IDataService } from '../common/data.service';
import { GeneralDataFacade } from '../_store/general/general.facade';

@Injectable({
	providedIn: 'root',
})
export class AuthBagService {
	authStatus = new Subject<boolean>();
	loggedUserId: number | null = null;
	isLogged = false;
	private tokenRefreshTimeout: ReturnType<typeof setTimeout>;

	constructor(
		private readonly router: Router,
		private readonly localStorage: LocalStorageService,
		private readonly generalDataFacade: GeneralDataFacade,
	) {
		this.isLogged = !!this.tokenGet();
		this.generalDataFacade.updateIsLoggedIn(this.isLogged); // TODO: use isLoggedIn from store everywhere
	}

	authenticate(result: { access: string; refresh?: string }): Promise<boolean> {
		return new Promise((resolve) => {
			if (!result.access || !result.refresh) {
				resolve(false);
			}
			this.tokensSet(result.access, result.refresh);
			resolve(true);
		});
	}

	tokenGet(): string {
		return this.localStorage.getItem('tokenBag');
	}

	tokensSet(token: string, tokenRefresh: string): void {
		this.localStorage.setItem('tokenBag', token);
		this.localStorage.setItem('tokenBagRefresh', tokenRefresh);
	}

	unauth(): void {
		this.localStorage.removeItem('tokenBag');
		this.localStorage.removeItem('tokenBagRefresh');
		this.localStorage.removeItem('loggedUserId');
		this.authStatus.next(false);
		this.loggedUserId = null;
		this.isLogged = false;
		this.generalDataFacade.updateIsLoggedIn(false);
		this.router.navigate(['/']);
	}

	tokenRefresh(dataSource: IDataService): void {
		if (this.tokenRefreshTimeout) {
			clearTimeout(this.tokenRefreshTimeout);
		}
		if (!this.localStorage.getItem('tokenBagRefresh')) {
			return;
		}
		dataSource.post(
			'token/refresh/',
			{ refresh: this.localStorage.getItem('tokenBagRefresh') },
			(result: { access: string }) => {
				this.localStorage.setItem('tokenBag', result.access);
				this.tokenRefreshTimeout = setTimeout(() => {
					this.tokenRefresh(dataSource);
				}, 1800000); // 30 min
			},
		);
	}
}

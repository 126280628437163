/* eslint @typescript-eslint/no-explicit-any: 0 */
import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { AuthBagService } from '@/auth/auth-bag.service';
import { catchError, throwError } from 'rxjs';
import { EnvironmentService } from '@/services/environment/environment.service';

export interface Result<T> {
	next: string | null;
	previous: string | null;
	results: T[];
	count: number;
}

export interface IDataService {
	get(url: string, callback: () => void): void;

	post(url: string, data: Record<string, any>, callback: (result: any, error?: any) => void): void;

	postWithoutErrorHandling?(url: string, data: Record<string, any>, callback: () => void): void;
}

@Injectable({
	providedIn: 'root',
})
export class DataService {
	private readonly apiURL = this.environmentService.environment?.apiUrl;

	constructor(
		private readonly http: HttpClient,
		private readonly auth: AuthBagService,
		private readonly environmentService: EnvironmentService,
	) {}

	httpHeaders(): { headers: HttpHeaders } {
		return { headers: this.httpHeadersGet() };
	}

	get(url: string, callback: (result: any, error?: any) => void): void {
		this.http
			.get(this.apiURL + url, { headers: this.httpHeadersGet() })
			.pipe(
				catchError((error) => {
					if (error.status === 401) {
						this.auth.unauth();
					}
					callback(false, error);
					return throwError(() => error);
				}),
			)
			.subscribe(
				(result) => {
					callback(result, false);
				},
				(error) => {
					callback(false, error);
				},
			);
	}

	post(url: string, data: object, callback: (result: any, error?: any) => void): void {
		this.http
			.post(this.apiURL + url, data, this.httpHeaders())
			.pipe(
				catchError((error) => {
					if (error.status === 401) {
						this.auth.unauth();
					}
					callback(false, error);
					return throwError(() => error);
				}),
			)
			.subscribe(
				(result) => {
					callback(result, false);
				},
				(error) => {
					callback(false, error);
				},
			);
	}

	/**
	 * Method used for making reservation only.
	 *
	 * Default error handling for post request doesn't allow handling nested objects in error string
	 * With this method developer can do whatever he wants with the error object
	 */
	postWithoutErrorHandling(url: string, data: object, callback: (result: any, error?: any) => void): void {
		this.http.post(this.apiURL + url, data, this.httpHeaders()).subscribe(
			(result) => {
				callback(result, false);
			},
			(error) => {
				this.auth.unauth();
				callback(false, error);
			},
		);
	}

	private httpHeadersGet(): HttpHeaders {
		if (!this.auth.tokenGet()) {
			return new HttpHeaders({
				'Content-Type': 'application/json',
			});
		}
		return new HttpHeaders({
			'Content-Type': 'application/json',
			Authorization: 'Bearer ' + this.auth.tokenGet(),
		});
	}
}

import { isPlatformServer } from '@angular/common';
import { Inject, Injectable, PLATFORM_ID } from '@angular/core';
import { BrowserStorage } from './browserstorage.interface';

@Injectable({
	providedIn: 'root',
})
export class LocalStorageService implements BrowserStorage {
	constructor(@Inject(PLATFORM_ID) private readonly platformId: string) {}

	setItem(key: string, value: string): void {
		if (isPlatformServer(this.platformId)) return;
		localStorage.setItem(key, value);
	}

	getItem(key: string): string {
		if (isPlatformServer(this.platformId)) return '';
		return localStorage.getItem(key) || '';
	}

	removeItem(key: string): void {
		if (isPlatformServer(this.platformId)) return;
		localStorage.removeItem(key);
	}

	clear(): void {
		if (isPlatformServer(this.platformId)) return;
		localStorage.clear();
	}
}
